<template>
  <div class="preview" style="weight: 100%">
    <div class="_6xe3">
      <div v-if="temCabecalho">
        <div v-if="cabecalhoTipo == 'TEXT'" style="padding: 7px 7px 6px 9px">
          <strong>{{ textoCabecalho }}</strong>
        </div>
        <div v-if="cabecalhoTipo == 'IMAGE'" class="p-1">
          <img
            :src="cabecalhoImagemVideo"
            style="max-width: 100%; border-radius: 3px"
          />
        </div>
        <div v-if="cabecalhoTipo == 'VIDEO'" class="p-1">
          <div v-if="!cabecalhoImagemVideo" class="_7r39 player" />
          <video
            v-else
            width="100%"
            height="180"
            controls
            style="border-radius: 3px"
          >
            <source :src="cabecalhoImagemVideo" type="video/mp4" />
          </video>
        </div>
        <div v-if="cabecalhoTipo == 'DOCUMENT'" class="p-1">
          <div class="_7r39 document" />
        </div>
      </div>
      <div
        style="padding: 7px 7px 6px 9px"
        v-html="formatTextToHTML(textoCorpo)"
      ></div>

      <div
        class="d-flex justify-content-between w-100"
        style="padding: 7px 7px 6px 9px"
      >
        <div v-if="temRodape" class="_7qiw">
          {{ rodape }}
        </div>
        <time aria-hidden="true" class="_6xe5">{{ hora }}</time>
      </div>

      <div v-if="temBotao" style="padding: 7px 7px 6px 9px; min-width: 200px">
        <div
          class="d-flex justify-content-center w-100 py-3"
          v-for="(botao, key) in botoes"
          style="border-top: 1px solid #dadde1"
          :key="key"
        >
          <div :class="`icone ${botao.type.toLowerCase()}`" />
          <span class="_alr0 ml-2">{{ botao.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "PreviewWhatsapp",
  components: {},
  props: {
    value: {
      type: Array
    }
  },
  data() {
    return {
      previewWhatsapp: JSON.parse(JSON.stringify(this.value))
    };
  },
  computed: {
    hora() {
      return moment().format("LT");
    },
    textoCorpo() {
      const item = this.previewWhatsapp.find((i) => i.type == "BODY");
      if (item.example && item.example.body_text) {
        item.example.body_text[0].forEach((v, index) => {
          item.text = item.text.replaceAll(`{{${index + 1}}}`, v);
        });
      }
      return item.text ?? "";
    },
    temRodape() {
      const item = this.previewWhatsapp.find((i) => i.type == "FOOTER");
      return item ? true : false;
    },
    rodape() {
      const item = this.previewWhatsapp.find((i) => i.type == "FOOTER");
      return item.text;
    },
    temBotao() {
      const item = this.previewWhatsapp.find((i) => i.type == "BUTTONS");
      return item ? true : false;
    },
    botoes() {
      const item = this.previewWhatsapp.find((i) => i.type == "BUTTONS");
      if (item.buttons.length > 3) {
        let buttons = item.buttons.slice(0, 2);
        buttons.push({ type: "LIST", text: "Ver todos" });
        return buttons;
      }
      return item.buttons ?? [];
    },
    temCabecalho() {
      const item = this.previewWhatsapp.find((i) => i.type == "HEADER");
      return item ? true : false;
    },
    cabecalhoTipo() {
      const item = this.previewWhatsapp.find((i) => i.type == "HEADER");
      return item.format ?? null;
    },
    cabecalho() {
      const item = this.previewWhatsapp.find((i) => i.type == "HEADER");
      if (item.example && item.example.header_text) {
        item.example.header_text.forEach((v, index) => {
          item.text = item.text.replaceAll(`{{${index + 1}}}`, v);
        });
      }
      return item ?? null;
    },
    textoCabecalho: {
      get() {
        const item = this.previewWhatsapp.find((i) => i.type == "HEADER");
        if (item.example && item.example.header_text) {
          item.example.header_text.forEach((v, index) => {
            item.text = item.text.replaceAll(`{{${index + 1}}}`, v);
          });
        }
        return item.text ?? "";
      }
    },
    cabecalhoImagemVideo() {
      const item = this.previewWhatsapp.find((i) => i.type == "HEADER");
      if (item.example && item.example.header_handle) {
        return item.example.header_handle;
      }
      return "";
    }
  },
  methods: {
    formatTextToHTML(text) {
      text = text.replaceAll(
        /_([^_]+)_/g,
        '<span style="font-style: italic">$1</span>'
      );
      text = text.replaceAll(/\*([^*]+)\*/g, "<strong>$1</strong>");
      text = text.replaceAll(/~([^~]+)~/g, "<s>$1</s>");
      text = text.replaceAll(/```([^`]+)```/g, "<code>$1</code>");
      text = text.replaceAll("\n", "<br>");
      return text;
    }
  },
  watch: {
    value: {
      handler(novo) {
        this.previewWhatsapp = JSON.parse(JSON.stringify(this.value));
        this.$forceUpdate();
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style scoped>
.preview {
  background-color: #e5ddd5;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
  padding: 20px 20px;
}
.preview::before {
  background: url(assets/background.png);
  background-size: 366.5px 666px;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.06;
  position: absolute;
  top: 0;
  width: 100%;
}

._6xe3 {
  background-color: #fff;
  border-radius: 7.5px;
  border-top-left-radius: 0;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
  min-height: 20px;
  position: relative;
  word-wrap: break-word;
  box-sizing: border-box;
  display: inline-block;
  font-family: BlinkMacSystemFont, -apple-system, Roboto, Arial, sans-serif;
  max-width: 280px;
  position: relative;
}

._6xe3::after {
  background: url(assets/orelhinha.png) 50% 50% no-repeat;
  background-size: contain;
  content: "";
  height: 19px;
  left: -12px;
  position: absolute;
  top: 0;
  width: 12px;
}

._6xe5 {
  bottom: 3px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px;
  height: 15px;
  line-height: 15px;
  /* position: absolute; */
  right: 7px;
}

.icone {
  background-repeat: no-repeat;
  background-size: contain;
  flex-shrink: 0;
  height: 16px;
  opacity: 0.8;
  width: 16px;
}

.url {
  background-image: url(assets/url.png);
}

.copy_code {
  background-image: url(assets/copy_code.png);
}

.otp {
  background-image: url(assets/copy_code.png);
}

.phone_number {
  background-image: url(assets/phone_number.png);
}
.quick_reply {
  background-image: url(assets/quick_reply.png);
}
.list {
  background-image: url(assets/list.png);
}

._alr0 {
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
}

._7r39 {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  border-radius: 4px;
  box-sizing: border-box;
  min-width: 240px;
  min-height: 180px;
}

.document {
  background-image: url(assets/document.png);
}
.player {
  background-image: url(assets/player.png);
}

._7qiw {
  color: rgba(0, 0, 0, 0.45);
  font-size: 13px;
  line-height: 17px;
  padding: 0 7px 8px 9px;
}
</style>
