<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center h-75"
      v-if="carregando"
    >
      <v-progress-circular indeterminate size="32" color="#356a7c" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ef720f"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>
    <div
      v-else
      class="d-flex flex-column justify-content-between align-items-between h-100"
    >
      <div class="d-flex flex-column justify-content-between h-100 w-100">
        <b-table
          id="tabelaTemplate"
          small
          :current-page="currentPage"
          :fields="fields"
          :items="templatesFiltrados"
          :per-page="perPage"
          responsive="sm"
          hover
          :sort-desc="true"
        >
          <template #cell(status)="data">
            <label class="mt-3">
              <span
                :class="`label ${status(data.value)[1]} label-inline mr-2`"
                v-b-tooltip.hover.right
                :title="`${
                  data.item.rejected_reason != 'NONE'
                    ? data.item.rejected_reason
                    : ''
                }`"
                >{{ status(data.value)[0] }}
                <b-icon
                  v-if="data.item.rejected_reason != 'NONE'"
                  class="ml-1"
                  icon="exclamation-triangle"
                ></b-icon>
              </span>
            </label>
          </template>

          <template #cell(category)="data">
            <label class="mt-3">
              <span>{{ categoriaTraducao(data.item.category) }}</span>
            </label>
          </template>

          <template #cell(conta)="data">
            <label class="mt-3">
              <span>{{ nomeContaTemplate(data.item._conta_id) }}</span>
            </label>
          </template>

          <template #cell(opcoes)="row">
            <i>{{ row.value }}</i>
            <b-dropdown
              no-caret
              variant="outline-secondary"
              id="dropdown-dropleft"
              dropleft
              class="border-0"
            >
              <template #button-content>
                <b-icon-three-dots-vertical />
              </template>
              <!-- <b-dropdown-item href="#">Detalhes</b-dropdown-item> -->
              <b-dropdown-item href="#" @click="editarTemplate(row.item)"
                >Editar</b-dropdown-item
              >

              <b-dropdown-item
                @click="mostrarDeletarTemplateSwal(row.item)"
                href="#"
                >Remover</b-dropdown-item
              >
              <b-dropdown-item :id="`popover-target-${row.index}`">
                Visualização
              </b-dropdown-item>
              <b-popover
                :target="`popover-target-${row.index}`"
                triggers="hover"
              >
                <template #title>Visualização</template>
                <PreviewWhatsapp v-model="row.item.components" />
              </b-popover>
            </b-dropdown>
          </template>
        </b-table>
        <div class="d-flex w-100">
          <b-row class="w-100">
            <b-col md="4"
              ><b-form-select
                v-model="perPage"
                :options="opcoesPaginacao"
              ></b-form-select
            ></b-col>
            <b-col md="8"
              ><b-pagination
                v-model="currentPage"
                :total-rows="this.templatesFiltrados.length"
                :per-page="perPage"
                align="fill"
              ></b-pagination
            ></b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../../../core/services/api.service";
import Swal from "sweetalert2";
import PreviewWhatsapp from "./PreviewWhatsapp.vue";
import { mapGetters } from "vuex";

export default {
  props: {
    filtros: {
      type: Object,
      default: () => {
        return {};
      }
    },
    contas: {
      type: Array
    }
  },
  components: {
    PreviewWhatsapp
  },
  data() {
    return {
      currentPage: 0,
      perPage: 7,
      templates: [],
      carregando: false,
      category: {
        AUTHENTICATION: "Autenticação",
        MARKETING: "Marketing",
        UTILITY: "Utilitário"
      },
      fields: [
        {
          key: "name",
          label: "Nome do modelo",
          sortable: true,
          class: "cursor-pointer align-middle",
          thStyle: { width: "60px !important" }
        },
        {
          key: "category",
          label: "Categoria",
          class: "text-center",
          thStyle: { width: "60px !important" }
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          class: "cursor-pointer align-middle",
          thStyle: { width: "60px !important" }
        },
        {
          key: "conta",
          label: "Conta",
          class: "text-center",
          thStyle: { width: "60px !important" }
        },
        {
          key: "opcoes",
          label: "Opções",
          class: "text-center",
          thStyle: { width: "60px !important" }
        }
      ],
      opcoesPaginacao: [
        {
          text: "Exibir 7 registros por página",
          value: "7"
        },
        {
          text: "Exibir 15 registros por página",
          value: "15"
        },
        {
          text: "Exibir 30 registros por página",
          value: "30"
        }
      ]
    };
  },
  methods: {
    async carregarTemplates() {
      const fields = []; // ["name", "status"];
      let contas = JSON.parse(JSON.stringify(this.contas));
      contas.push({
        name: this.currentUserPersonalInfo.conta,
        value: this.currentUserPersonalInfo.conta_id
      });
      contas = contas.map((c, k) => c.value).filter((t) => t != undefined);
      contas = [...new Set(contas)];
      contas = contas.join(",");
      const response = await ApiService.get(
        `template${fields.length ? `?fields=${fields}` : ""}${
          contas.length ? "?conta_id=" + contas : ""
        }`
      );
      this.templates = response.data.data;
    },
    status(status) {
      const t = {
        APPROVED: ["Aprovado", "label-success"],
        IN_APPEAL: ["Em analise", "label-secondary"],
        PENDING: ["Pendente", "label-warning"],
        REJECTED: ["Rejeitado", "label-danger"],
        PENDING_DELETION: ["Remoção pendente", "label-warning"],
        DELETED: ["Removido", ""],
        DISABLED: ["Desabilitado", ""],
        PAUSED: ["Pausado", ""],
        LIMIT_EXCEEDED: ["Limite excedido", ""]
      };

      return t[status] ?? "";
    },
    categoriaTraducao(category) {
      return this.category[category] ?? category;
    },
    contaTemplate(conta_id) {
      const contaAtualEmContas = this.contas.some(
        (c) => c.value == this.currentUserPersonalInfo.conta_id
      );
      let contaComAtual;
      if (!contaAtualEmContas) {
        contaComAtual = [
          ...this.contas,
          {
            name: this.currentUserPersonalInfo.conta,
            value: this.currentUserPersonalInfo.conta_id,
            identificacao: this.currentUserPersonalInfo.con_nome_identificacao
          }
        ];
      } else {
        contaComAtual = [...this.contas];
      }
      const c = contaComAtual.find((c) => c.value == conta_id);
      return (
        c ?? {
          identificacao: "Conta não encontrada"
        }
      );
    },
    nomeContaTemplate(conta_id) {
      const conta = this.contaTemplate(conta_id);
      return conta?.identificacao ?? conta?.name ?? "";
    },
    editarTemplate(template) {
      this.$router.push({
        name: "automacao.template.cadastro",
        params: {
          templateObj: template,
          conta: this.contaTemplate(template._conta_id)
        }
      });
    },
    mostrarDeletarTemplateSwal(template) {
      Swal.fire({
        title: 'Você deseja remover o template "' + template.name + '"?',
        text: "Não será possivel reverter essa ação!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero remover!",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("template", { name: template.name }).then((_) => {
            this.carregarTemplates();
            Swal.fire(
              "Template Removido",
              `Template "${template.name}" foi removido.`,
              "success"
            );
          });
        }
      });
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    templatesFiltrados() {
      const templatesFiltrados = [];
      this.templates.forEach((t) => {
        if (this.filtros.subcontas.includes(parseInt(t.conta_id))) {
          t.templates.forEach((k) => {
            if (
              this.filtros.status.includes(k.status) &&
              k.name.includes(this.filtros.nome)
            ) {
              k._conta_id = t.conta_id;
              templatesFiltrados.push(k);
            }
          });
        }
      });
      return templatesFiltrados;
    }
  }
};
</script>

<style scoped></style>
